import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import { useUser } from '../../contexts/UserContext';
import PrivateLayout from '../layouts/PrivateLayout';
import Loading from '../common/Loading';
import { useFetchClaimProfile } from '../../services/claims';
import {
  useUpdateClaimActivity,
  useDeleteClaimActivity,
} from '../../services/activity';
import SideBar from './components/SideBar';
import Tabs from './components/Tabs';
import ActivityTab from './components/ActivityTab';
import ClaimDetailsTab from './components/ClaimDetailsTab';
import FileBrowser from '../fileBrowser/FileBrowser';
import TranscriptsTab from './components/TranscriptsTab';
import GeneratedContentTab from './components/GeneratedContentTab';
import ActivityModal from './components/ActivityModal';

const Dashboard = () => {
  const { user } = useUser();
  const { claimId } = useParams();
  const queryClient = useQueryClient();
  const { isLoading, data } = useFetchClaimProfile(claimId);
  const [showActivity, setShowActivity] = useState(null);

  const updateClaimActivity = useUpdateClaimActivity(() => {
    toast.success('The activity has been successfully completed.');
    queryClient.invalidateQueries({ queryKey: ['claim-profile'] });
  });

  const deleteClaimActivity = useDeleteClaimActivity(() => {
    toast.success('The activity has been successfully deleted.');
    queryClient.invalidateQueries({ queryKey: ['claim-profile'] });
  });

  return (
    <PrivateLayout>
      {isLoading ? (
        <Loading />
      ) : (
        <Row>
          <Col xs={12} sm={5} md={4}>
            <SideBar
              data={data}
              user={user}
              onCreateActivityClicked={() => setShowActivity({ actId: 'new' })}
            />
          </Col>
          <Col>
            <Tabs
              tabs={[
                {
                  label: 'Activity',
                  content: (
                    <ActivityTab
                      claim={data.claim}
                      activity={data.activity}
                      onActivityAction={(action, activity) => {
                        switch (action) {
                          case 'edit':
                            setShowActivity(activity);
                            break;
                          case 'delete':
                            if (
                              window.confirm(
                                'Are you sure you want to delete this activity? This cannot be undone.'
                              )
                            ) {
                              deleteClaimActivity.mutate({
                                claimId,
                                actId: activity.actId,
                              });
                            }
                            break;
                          case 'complete':
                            if (
                              window.confirm(
                                'Are you sure you want to mark this item as completed?'
                              )
                            ) {
                              updateClaimActivity.mutate({
                                claimId,
                                actId: activity.actId,
                                payload: {
                                  actCompletedBy: user.userId,
                                  actCompletedOn: moment().toDate(),
                                },
                              });
                            }
                            break;
                          default:
                          // do nothing
                        }
                      }}
                    />
                  ),
                },
                {
                  label: 'Claim Details',
                  content: <ClaimDetailsTab claim={data.claim} />,
                },
                {
                  label: 'Files',
                  content: (
                    <Card>
                      <Card.Body>
                        <FileBrowser
                          claimId={claimId}
                          files={data.claim.files}
                          onRefresh={(msg) => {
                            queryClient.invalidateQueries([
                              'claim-profile',
                              claimId,
                            ]);
                            toast.success(
                              msg || 'Your file was successfully uploaded.'
                            );
                          }}
                        />
                      </Card.Body>
                    </Card>
                  ),
                },
                {
                  label: 'Transcripts',
                  content: (
                    <TranscriptsTab
                      claim={data.claim}
                      transcripts={data.transcripts}
                      questionnaireMatrix={data.questionnaireMatrix}
                    />
                  ),
                },
                {
                  label: 'Generated Content',
                  content: (
                    <GeneratedContentTab
                      claim={data.claim}
                      addons={data.addons}
                      questionnaireMatrix={data.questionnaireMatrix}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      )}

      <ActivityModal
        claimId={claimId || ''}
        activity={showActivity || {}}
        show={showActivity !== null}
        toggle={() => setShowActivity(null)}
      />
    </PrivateLayout>
  );
};

export default Dashboard;
