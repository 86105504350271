import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../contexts/UserContext';
import { useFileUpload } from '../../hooks/useFileUpload';
import {
  useDeleteClaimFile,
  useGetPresignedClaimFile,
  useToggleFileLock,
} from '../../services/claims';

import FolderPath from './components/FolderPath';
import GridView from './components/GridView';
import ListView from './components/ListView';

const FOLDERS = [
  { label: 'VA Forms', path: 'va_forms' },
  { label: 'Evidence', path: 'evidence' },
  { label: 'Statements', path: 'statements' },
  { label: 'Admin Folder', path: 'admin-folder', role: 'admin' },
];

const FileBrowser = ({ claimId, files, onRefresh }) => {
  const { user, token } = useUser();
  const fileRef = useRef();
  const [selFileId, setSelFileId] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [view] = useState('grid');

  const { busy, startUpload } = useFileUpload(claimId, () => {
    fileRef.current.value = '';

    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  });

  const delFile = useDeleteClaimFile(() => {
    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  });

  const toggleFileLock = useToggleFileLock((res) => {
    if (typeof onRefresh === 'function') {
      onRefresh(
        `The file was successfully ${res.fileLocked ? 'locked' : 'unlocked'}.`
      );
    }
  });

  const openFile = useGetPresignedClaimFile((res) => {
    window.open(res.url, '_blank');
  });

  const FOLDER_LIST = useMemo(() => {
    return FOLDERS.filter(
      (f) => typeof f.role === 'undefined' || f.role === user.userType
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId]);

  useEffect(() => {
    if (selFileId) {
      fileRef.current.click();
    }
  }, [selFileId]);

  const filterFiles = () => {
    const reg = new RegExp(
      !currentFolder
        ? `/(${FOLDERS.map((f) => f.path).join('|')})/`
        : `/${currentFolder.path}/`
    );

    let newFiles = [];

    if (currentFolder) {
      newFiles = files.filter((f) => reg.test(f.fileLocation));
    } else {
      newFiles = files.filter((f) => !reg.test(f.fileLocation));
    }

    setCurrentFiles(newFiles);
  };

  useEffect(() => {
    filterFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder]);

  useEffect(() => {
    filterFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <>
      <input
        type="file"
        ref={fileRef}
        onChange={(e) => {
          startUpload(
            e.target.files[0],
            selFileId,
            currentFolder ? currentFolder.path : null
          );
        }}
        style={{ display: 'none' }}
      />
      <Row>
        <Col>
          <FolderPath
            folder={currentFolder}
            onClick={(folder) => {
              setCurrentFolder(folder);
            }}
          />
        </Col>
        <Col className="text-end">
          <Button
            type="primary"
            size="sm"
            onClick={() => fileRef.current.click()}
          >
            <FontAwesomeIcon icon={faFileUpload} /> Upload New File
          </Button>
        </Col>
      </Row>
      <hr />

      {busy ? (
        <div className="mb-3 text-center">
          <strong>Uploading...</strong>
        </div>
      ) : null}

      {openFile.isLoading ? (
        <div className="mb-3 text-center">
          <strong>Preparing file...</strong>
        </div>
      ) : null}

      {view === 'grid' ? (
        <GridView
          folders={!currentFolder ? FOLDER_LIST : []}
          files={currentFiles}
          onFolderClick={(folder) => {
            setCurrentFolder(folder);
          }}
          onFileClick={(file, action) => {
            switch (action) {
              case 'file:open':
                openFile.mutate({ claimId, fileId: file.fileId });
                break;

              case 'file:download':
                window.open(
                  `${process.env.REACT_APP_API_URL}/claims/${claimId}/files/${file.fileId}/populate?jwt=${token}`,
                  '_blank'
                );
                break;

              case 'file:replace':
                setSelFileId(file.fileId);
                break;

              case 'file:delete':
                if (
                  window.confirm('Are you sure you want to delete this file?')
                ) {
                  delFile.mutate({ claimId, fileId: file.fileId });
                }
                break;

              case 'file:toggle:lock':
                toggleFileLock.mutate({ claimId, fileId: file.fileId });
                break;

              default:
                return;
            }
          }}
        />
      ) : (
        <ListView folders={[]} files={currentFiles} />
      )}
    </>
  );
};

export default FileBrowser;
